export const GET_PMO_ACTIVITIES_REQUEST = "GET_PMO_ACTIVITIES_REQUEST";
export const GET_PMO_ACTIVITIES_SUCCESS = "GET_PMO_ACTIVITIES_SUCCESS";
export const GET_PMO_ACTIVITIES_FAILURE = "GET_PMO_ACTIVITIES_FAILURE";

export const GET_PMO_ARCHIVE_ACTIVITIES_REQUEST =
  "GET_PMO_ARCHIVE_ACTIVITIES_REQUEST";
export const GET_PMO_ARCHIVE_ACTIVITIES_SUCCESS =
  "GET_PMO_ARCHIVE_ACTIVITIES_SUCCESS";
export const GET_PMO_ARCHIVE_ACTIVITIES_FAILURE =
  "GET_PMO_ARCHIVE_ACTIVITIES_FAILURE";

export const POST_PMO_ACTIVITY_REQUEST = "POST_PMO_ACTIVITY_REQUEST";
export const POST_PMO_ACTIVITY_SUCCESS = "POST_PMO_ACTIVITY_SUCCESS";
export const POST_PMO_ACTIVITY_FAILURE = "POST_PMO_ACTIVITY_FAILURE";

export const CONVERT_PMO_TYPE_REQUEST = "CONVERT_PMO_TYPE_REQUEST";
export const CONVERT_PMO_TYPE_SUCCESS = "CONVERT_PMO_TYPE_SUCCESS";
export const CONVERT_PMO_TYPE_FAILURE = "CONVERT_PMO_TYPE_FAILURE";

export const DELETE_PMO_ACTIVITY_REQUEST = "DELETE_PMO_ACTIVITY_REQUEST";
export const DELETE_PMO_ACTIVITY_SUCCESS = "DELETE_PMO_ACTIVITY_SUCCESS";
export const DELETE_PMO_ACTIVITY_FAILURE = "DELETE_PMO_ACTIVITY_FAILURE";

export const EDIT_PMO_ACTIVITY_REQUEST = "EDIT_PMO_ACTIVITY_REQUEST";
export const EDIT_PMO_ACTIVITY_SUCCESS = "EDIT_PMO_ACTIVITY_SUCCESS";
export const EDIT_PMO_ACTIVITY_FAILURE = "EDIT_PMO_ACTIVITY_FAILURE";

export const ADD_PMO_LOG_REQUEST = "ADD_PMO_LOG_REQUEST";
export const ADD_PMO_LOG_SUCCESS = "ADD_PMO_LOG_SUCCESS";
export const ADD_PMO_LOG_FAILURE = "ADD_PMO_LOG_FAILURE";

export const GET_LOG_REQUEST = "GET_LOG_REQUEST";
export const GET_LOG_SUCCESS = "GET_LOG_SUCCESS";
export const GET_LOG_FAILURE = "GET_LOG_FAILURE";

export const FILTER_PMO_ACTIVITY_REQUEST = "FILTER_PMO_ACTIVITY_REQUEST";
export const FILTER_PMO_ACTIVITY_SUCCESS = "FILTER_PMO_ACTIVITY_SUCCESS";
export const FILTER_PMO_ACTIVITY_FAILURE = "FILTER_PMO_ACTIVITY_FAILURE";

export const GET_ACTIVITIES_BY_TEMPLATES_REQUEST =
  "GET_ACTIVITIES_BY_TEMPLATES_REQUEST";
export const GET_ACTIVITIES_BY_TEMPLATES_SUCCESS =
  "GET_ACTIVITIES_BY_TEMPLATES_SUCCESS";
export const GET_ACTIVITIES_BY_TEMPLATES_FAILURE =
  "GET_ACTIVITIES_BY_TEMPLATES_FAILURE";

export const SWAP_PMO_ACTIVITIES_REQUEST = "SWAP_PMO_ACTIVITIES_REQUEST";
export const SWAP_PMO_ACTIVITIES_SUCCESS = "SWAP_PMO_ACTIVITIES_SUCCESS";
export const SWAP_PMO_ACTIVITIES_FAILURE = "SWAP_PMO_ACTIVITIES_FAILURE";

export const ADD_PMO_DEPENDENCY_REQUEST = "ADD_PMO_DEPENDENCY_REQUEST";
export const ADD_PMO_DEPENDENCY_SUCCESS = "ADD_PMO_DEPENDENCY_SUCCESS";
export const ADD_PMO_DEPENDENCY_FAILURE = "ADD_PMO_DEPENDENCY_FAILURE";
