import { findGreatestDueDate, findTeamMember } from "../../utils/helpers";
import * as types from "./types";
const initState = {
  loading: false,
  data: [],
  success: false,
  activityLogs: [],
  row: null,
  myActivities: [],
  fileMedia: [],
  imageMedia: [],
  cardData: {},
  confetti: false,
  mediaData: null,
  archivedActivities: [],
  activeNote: null,
  allLogs: [],
  currentEdit: null,
};

const activitiesReducer = (state = initState, action) => {
  switch (action.type) {
    //get activities
    case types.GET_ACTIVITIES_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.GET_ACTIVITIES_SUCCESS:
      let array1 = [];
      action.payload?.map((item, index) => {
        let result = state?.data?.find((elem) => elem?._id == item?._id);
        let result2 = array1?.find((elem) => elem?._id == item?._id);

        if (!result && !result2) {
          array1?.push(item);
        }
      });
      return {
        ...state,
        loading: false,
        success: true,
        data: [...state.data, ...array1],
      };
    case types.GET_ACTIVITIES_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //get activities by workstreams
    case types.GET_ACTIVITIES_BY_WORKSTREAMS_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.GET_ACTIVITIES_BY_WORKSTREAMS_SUCCESS:
      let array2 = [];
      action.payload?.map((item, index) => {
        let result = state?.data?.find((elem) => elem?._id == item?._id);
        let result2 = array2?.find((elem) => elem?._id == item?._id);

        if (!result && !result2) {
          array2?.push(item);
        }
      });
      return {
        ...state,
        loading: false,
        success: true,
        data: [...state.data, ...array2],
      };
    case types.GET_ACTIVITIES_BY_WORKSTREAMS_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //get archived activities
    case types.GET_ARCHIVE_ACTIVITIES_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.GET_ARCHIVE_ACTIVITIES_SUCCESS:
      //;
      return {
        ...state,
        loading: false,
        success: true,
        archivedActivities: action.payload,
      };
    case types.GET_ARCHIVE_ACTIVITIES_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //add activity
    case types.POST_ACTIVITY_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.POST_ACTIVITY_SUCCESS:
      if (action.payload.targetIndex) {
        let newData = [...state.data];

        // Insert the new object at the specified index
        newData = newData?.map((elem) =>
          elem?.index == action.payload.targetIndex ||
          elem?.index > action.payload.targetIndex
            ? { ...elem, index: elem?.index + 1 }
            : elem
        );
        //;
        // console.log(newData);
        // //;

        // Update the index field of the remaining objects
        // newData = newData.forEach((item, index) => {
        //   item.index = index + 1;
        // });
        // //;
        return {
          ...state,
          loading: false,
          success: true,
          data: [...newData, action.payload.data],
          currentEdit: action.payload.data,
        };
      } else {
        return {
          ...state,
          loading: false,
          success: true,
          data: [...state.data, action.payload.data],
          currentEdit: action.payload.data,
        };
      }
    case types.POST_ACTIVITY_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //convert type
    case types.CONVERT_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.CONVERT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: state.data?.map((elem) =>
          elem?._id == action.payload._id ? action.payload : elem
        ),
        myActivities: state.myActivities?.map((elem) =>
          elem?._id == action.payload._id ? action.payload : elem
        ),
      };
    case types.CONVERT_TYPE_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //delete activity
    case types.DELETE_ACTIVITY_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.DELETE_ACTIVITY_SUCCESS:
      let res = state?.data?.filter((elem) => elem?._id !== action.payload._id);
      res = res?.map((elem) =>
        elem?.index > action.payload.index
          ? { ...elem, index: elem.index - 1 }
          : elem
      );
      return {
        ...state,
        loading: false,
        success: true,
        data: res,
      };
    case types.DELETE_ACTIVITY_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //edit activity
    case types.EDIT_ACTIVITY_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.EDIT_ACTIVITY_SUCCESS:
      let activity = state?.data?.find(
        (elem) => elem?._id == action.payload.data._id
      );
      let modifyData = [];
      if (
        activity?.status !== action.payload.data.status ||
        activity?.teamMember !== action.payload.data.teamMember ||
        activity?.deliverable !== action.payload.data.deliverable
      ) {
        state?.data?.map((elem) => {
          let depData = [];
          elem?.expandedRow?.dependencies?.map((dep) =>
            dep?.deliverable?._id == activity?._id
              ? depData.push({
                  ...dep,
                  deliverable: action.payload.data,
                })
              : depData.push(dep)
          );
          console.log(depData, "depData");
          modifyData.push({
            ...elem,
            expandedRow: { ...elem?.expandedRow, dependencies: depData },
          });
        });
        modifyData = modifyData?.map((elem) =>
          elem?._id == action.payload.data?._id ? action.payload?.data : elem
        );
      }
      if (
        activity.dueDate &&
        activity.dueDate !== action.payload.data.dueDate
      ) {
        modifyData =
          modifyData.length > 0
            ? modifyData
            : state?.data?.map((elem) =>
                elem?._id == action.payload.data?._id
                  ? action.payload?.data
                  : elem
              );
        let activityData = [];
        modifyData?.map((el) => {
          let depData = el?.expandedRow?.dependencies?.filter(
            (el) => el.deliverable?._id == action.payload.data._id
          );
          if (depData.length > 0) {
            if (
              new Date(action.payload.data.dueDate) > new Date(el?.start_date)
            ) {
              activityData.push({
                ...el,
                start_date: action.payload.data.dueDate,
              });
            } else {
              let greaterVal = findGreatestDueDate(
                el?.expandedRow?.dependencies?.map((dep) => {
                  return { ...dep?.deliverable };
                })
              );
              if (
                new Date(action.payload.data.dueDate) >
                new Date(greaterVal.dueDate)
              ) {
                activityData.push({
                  ...el,
                  start_date: action.payload.data.dueDate,
                });
              } else {
                activityData.push({
                  ...el,
                  start_date: greaterVal.dueDate,
                });
              }
            }
          } else activityData.push(el);
        });
        modifyData = activityData;
      } else if (modifyData.length == 0) {
        modifyData = state?.data?.map((elem) =>
          elem?._id == action.payload.data?._id ? action.payload?.data : elem
        );
      }
      return {
        ...state,
        loading: false,
        success: true,
        confetti: action.payload?.confetti,
        data: modifyData,
        myActivities: state?.myActivities?.map((elem) =>
          elem?._id == action.payload.data?._id ? action.payload?.data : elem
        ),
        editValue: action.payload.data,
        cardData: action.payload?.data,
        currentEdit: action.payload.data,
      };
    case types.EDIT_ACTIVITY_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //add activity log
    case types.ADD_LOG_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.ADD_LOG_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        allLogs: [action.payload, ...state.allLogs],
      };
    case types.ADD_LOG_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //get activity logs
    case types.GET_LOG_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.GET_LOG_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        activityLogs: action.payload,
      };
    case types.GET_LOG_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //filter activities
    case types.FILTER_ACTIVITY_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.FILTER_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: state?.data?.filter(
          (elem) => elem?.workstream?._id !== action.payload
        ),
      };
    case types.FILTER_ACTIVITY_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //clear table
    case types.CLEAR_TABLE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.CLEAR_TABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: [],
        activityLogs: [],
        myActivities: [],
      };
    case types.CLEAR_TABLE_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //clear table
    case types.GET_CLIENT_ACT_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.GET_CLIENT_ACT_SUCCESS:
      let array = [];
      action.payload?.map((item) => {
        let result = state?.data?.find((elem) => elem?._id == item?._id);
        if (!result) {
          array?.push(item);
        }
      });

      return {
        ...state,
        loading: false,
        success: true,
        data: [...state.data, ...array],
      };
    case types.GET_CLIENT_ACT_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //clear table
    case types.GET_MEMBER_ACT_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.GET_MEMBER_ACT_SUCCESS:
      let arr = [];
      action.payload?.map((item, index) => {
        let result = state?.data?.find((elem) => elem?._id == item?._id);
        let result2 = arr?.find((elem) => elem?._id == item?._id);

        if (!result && !result2) {
          arr?.push(item);
        }
      });
      return {
        ...state,
        loading: false,
        success: true,
        data: [...state.data, ...arr],
      };
    case types.GET_MEMBER_ACT_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //upload media
    case types.UPLOAD_MEDIA_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.UPLOAD_MEDIA_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: state.data?.map((item) =>
          item?._id == action.payload._id ? action.payload : item
        ),
        myActivities: state.myActivities?.map((item) =>
          item?._id == action.payload._id ? action.payload : item
        ),
        cardData: action.payload,
      };
    case types.UPLOAD_MEDIA_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //my-activities
    case types.MY_ACTIVITIES_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.MY_ACTIVITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        myActivities: action.payload,
        isMyActivities: true,
      };
    case types.MY_ACTIVITIES_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //my-activities
    case types.CLEAR_MY_ACTIVITIES_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.CLEAR_MY_ACTIVITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        myActivities: [],
        isMyActivities: false,
      };
    case types.CLEAR_MY_ACTIVITIES_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //get-media
    case types.GET_MEDIA_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.GET_MEDIA_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        fileMedia: action.payload.filter((el) => el.type == "file"),
        imageMedia: action.payload.filter((el) => el.type == "image"),
      };
    case types.GET_MEDIA_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //delete-media
    case types.DELETE_MEDIA_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.DELETE_MEDIA_SUCCESS:
      let inputsData, outputsData, processData;
      if (action.payload.table == "Input") {
        inputsData = state?.data?.find(
          (elem) => elem?._id == action.payload.data.activityId
        ).expandedRow.inputs;
        //;
        inputsData = inputsData?.map((elem) =>
          elem._id == action.payload.data._id ? action.payload.data : elem
        );
      } else if (action.payload.table == "Output") {
        outputsData = state?.data?.find(
          (elem) => elem?._id == action.payload.data.activityId
        ).expandedRow.outputs;
        //;
        outputsData = outputsData?.map((elem) =>
          elem._id == action.payload.data._id ? action.payload.data : elem
        );
      } else if (action.payload.table == "process") {
        processData = state?.data?.find(
          (elem) => elem?._id == action.payload.data.activityId
        ).expandedRow.outputs;
        //;
        processData = processData?.map((elem) =>
          elem._id == action.payload.data._id ? action.payload.data : elem
        );
      }
      return {
        ...state,
        loading: false,
        success: true,
        fileMedia: state.fileMedia.filter(
          (el) => el._id !== action.payload._id
        ),
        imageMedia: state.imageMedia.filter(
          (el) => el._id !== action.payload._id
        ),
        data: state.data?.map((elem) =>
          elem._id == action.payload.data.activityId
            ? {
                ...elem,
                expandedRow: {
                  ...elem.expandedRow,
                  inputs:
                    action.payload.table == "Input"
                      ? inputsData
                      : elem.expandedRow.inputs,
                  outputs:
                    action.payload.table == "Output"
                      ? outputsData
                      : elem.expandedRow.outputs,
                  process:
                    action.payload.table == "process"
                      ? processData
                      : elem.expandedRow.process,
                },
              }
            : elem
        ),
      };
    case types.DELETE_MEDIA_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //get-media-by-id
    case types.GET_MEDIA_BYID_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.GET_MEDIA_BYID_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        mediaData: action.payload,
      };
    case types.GET_MEDIA_BYID_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //get-media
    case types.SET_CARD_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.SET_CARD_DATA_SUCCESS:
      //;
      return {
        ...state,
        loading: false,
        success: true,
        cardData: action.payload,
      };
    case types.SET_CARD_DATA_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //get-media
    case types.SWAP_ACTIVITIES_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.SWAP_ACTIVITIES_SUCCESS:
      let obj1 = action.payload.activeObj;
      let obj2 = action.payload.overObj;
      let arrResult = state?.data?.map((elem) => {
        if (elem._id == obj1._id) {
          return { ...elem, index: obj1.index };
        } else if (elem._id == obj2._id) {
          return { ...elem, index: obj2.index };
        } else {
          return elem;
        }
      });

      //;
      return {
        ...state,
        loading: false,
        success: true,
        data: arrResult,
      };
    case types.SWAP_ACTIVITIES_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //is-my-activities
    case types.IS_MY_ACTIVITIES_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.IS_MY_ACTIVITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        isMyActivities: true,
      };
    case types.IS_MY_ACTIVITIES_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //edit activity state
    case types.EDIT_ACTIVITY_STATE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.EDIT_ACTIVITY_STATE_SUCCESS:
      console.log(action.payload);
      if (action.payload.type == "add") {
        let activity = state.data?.find(
          (el) => el?._id == action.payload.data.activityId
        );

        return {
          ...state,
          loading: false,
          success: true,
          data: state?.data?.map((el) =>
            el?._id == activity._id
              ? {
                  ...el,
                  start_date:
                    new Date(action.payload.data.deliverable.dueDate) >
                    new Date(activity.start_date)
                      ? action.payload.data.deliverable.dueDate
                      : activity?.start_date,
                  expandedRow: {
                    ...el.expandedRow,
                    dependencies: [
                      ...el?.expandedRow?.dependencies,
                      action.payload.data,
                    ],
                  },
                }
              : el
          ),
        };
      } else {
        let activity = state.data?.find(
          (el) => el?._id == action.payload.data.activityId
        );
        let dependenciesArr = activity?.expandedRow?.dependencies?.filter(
          (el) => el?._id !== action.payload.linkId
        );

        return {
          ...state,
          loading: false,
          success: true,
          data: state?.data?.map((el) =>
            el?._id == activity._id
              ? {
                  ...el,
                  expandedRow: {
                    ...el.expandedRow,
                    dependencies: dependenciesArr,
                  },
                }
              : el
          ),
        };
      }
    case types.EDIT_ACTIVITY_STATE_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //get workstream state
    case types.GET_WORKSTREAM_LOG_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.GET_WORKSTREAM_LOG_SUCCESS:
      let logArr = [];
      action.payload?.map((item, index) => {
        let result = state?.allLogs?.find((elem) => elem?._id == item?._id);
        let result2 = logArr?.find((elem) => elem?._id == item?._id);

        if (!result && !result2) {
          logArr?.push(item);
        }
      });
      return {
        ...state,
        loading: false,
        success: true,
        allLogs: [...state.allLogs, ...logArr],
      };
    case types.GET_WORKSTREAM_LOG_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //get member logs
    case types.GET_MEMBER_LOG_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.GET_MEMBER_LOG_SUCCESS:
      let logArr2 = [];
      action.payload?.map((item, index) => {
        let result = state?.allLogs?.find((elem) => elem?._id == item?._id);
        let result2 = logArr2?.find((elem) => elem?._id == item?._id);

        if (!result && !result2) {
          logArr2?.push(item);
        }
      });
      return {
        ...state,
        loading: false,
        success: true,
        allLogs: [...state.allLogs, ...logArr2],
      };
    case types.GET_WORKSTREAM_LOG_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    // clear logs
    case types.ADD_DEPENDENCY_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.ADD_DEPENDENCY_SUCCESS:
      let dataArr;
      dataArr = state.data?.map((el) =>
        el._id == action.payload.id
          ? {
              ...el,
              start_date:
                action.payload.data.deliverable &&
                new Date(action.payload.data.deliverable.dueDate) >
                  new Date(el?.start_date)
                  ? action.payload.data.deliverable.dueDate
                  : el?.start_date,
              expandedRow:
                action.payload.target == "delete"
                  ? {
                      ...el.expandedRow,
                      [action.payload.tableType]: el.expandedRow[
                        action.payload.tableType
                      ]?.filter((elem) => elem._id !== action.payload.data._id),
                    }
                  : action.payload.target == "add"
                  ? {
                      ...el.expandedRow,
                      [action.payload.tableType]: [
                        ...el.expandedRow[action.payload.tableType],
                        action.payload.data,
                      ],
                    }
                  : {
                      ...el.expandedRow,
                      [action.payload.tableType]: el.expandedRow[
                        action.payload.tableType
                      ]?.map((elem) =>
                        elem._id == action.payload.data._id
                          ? action.payload.data
                          : elem
                      ),
                    },
            }
          : el
      );

      return {
        ...state,
        loading: false,
        success: true,
        data: dataArr,
      };
    case types.CLEAR_LOG_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    // clear logs
    case types.CLEAR_LOG_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.CLEAR_LOG_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        allLogs: [],
      };
    case types.CLEAR_LOG_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    // clear logs
    case types.EDIT_ALL_ACTIVITY_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.EDIT_ALL_ACTIVITY_SUCCESS:
      let editData = [];
      action.payload.map((editVal) => {
        editData = state.data.map((el) =>
          editVal._id == el._id ? editVal : el
        );
      });
      debugger;
      return {
        ...state,
        loading: false,
        success: true,
        data: editData,
      };
    case types.EDIT_ALL_ACTIVITY_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    default:
      return state;
  }
};

export default activitiesReducer;
