import * as types from "./types";

const initState = {
  loading: false,
  clients: [],
  teams: [],
  teamMembers: [],
  departments: [],
  pmo_deliverables: [],
  pmo_project: [],
  pmo_program: [],
  pmo_portfolio: [],
  project: [],
  program: [],
  portfolio: [],
  success: false,
  tabNum: "1",
  assignToMembers: [],
};

const filterDataReducer = (state = initState, action) => {
  switch (action.type) {
    //check
    case types.CHECK_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.CHECK_CLIENT_SUCCESS:
      if (
        state.clients.filter((item) => item?._id == action.payload._id).length >
        0
      ) {
        return {
          ...state,
          loading: false,
          success: true,
          tabNum: "1",
          clients: state.clients.filter((elem) =>
            elem._id !== action.payload._id ? elem : null
          ),
        };
      } else {
        return {
          ...state,
          tabNum: "1",
          loading: false,
          success: true,
          clients: [...state.clients, action.payload],
        };
        break;
      }
    case types.CHECK_CLIENT_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //check
    case types.CHECK_DEPARTMENT_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.CHECK_DEPARTMENT_SUCCESS:
      if (
        state.departments.filter((item) => item?._id == action.payload._id)
          .length > 0
      ) {
        return {
          ...state,
          loading: false,
          success: true,
          tabNum: "2",
          departments: state.departments.filter((elem) =>
            elem._id !== action.payload._id ? elem : null
          ),
        };
      } else {
        return {
          ...state,
          loading: false,
          tabNum: "2",
          success: true,
          departments: [...state.departments, action.payload],
        };
        break;
      }
    case types.CHECK_DEPARTMENT_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //check
    case types.CHECK_MEMBER_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.CHECK_MEMBER_SUCCESS:
      //;
      if (
        state.teamMembers.filter(
          (item) =>
            item?._id == action.payload._id &&
            item?.teamName == action.payload.teamName &&
            item?.teamId == action.payload.teamId
        ).length > 0
      ) {
        return {
          ...state,
          loading: false,
          success: true,
          tabNum: "4",
          teamMembers: state.teamMembers.filter((elem) =>
            elem._id == action.payload._id &&
            elem?.teamName == action.payload.teamName
              ? null
              : elem
          ),
        };
      } else {
        return {
          ...state,
          loading: false,
          success: true,
          tabNum: "4",
          teamMembers: [...state.teamMembers, action.payload],
        };
        break;
      }
    case types.CHECK_MEMBER_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //check
    case types.CHECK_ALL_MEMBER_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.CHECK_ALL_MEMBER_SUCCESS:
      let data1 = state.teamMembers;
      let data2 = action.payload.data;
      //;
      let data3 = [...data1, ...data2];

      //;
      return {
        ...state,
        loading: false,
        success: true,
        tabNum: "3",
        teamMembers: data3,
        teams: [...state.teams, action.payload.team],
      };

    case types.CHECK_ALL_MEMBER_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //check
    case types.UN_CHECK_ALL_MEMBER_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.UN_CHECK_ALL_MEMBER_SUCCESS:
      //;
      return {
        ...state,
        loading: false,
        success: true,
        teamMembers: state.teamMembers.filter(
          (el) => el.teamId !== action.payload
        ),
        teams: state.teams.filter((el) => el._id !== action.payload),
      };

    case types.UN_CHECK_ALL_MEMBER_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //check
    case types.CHECK_PMO_DELIVERABLE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.CHECK_PMO_DELIVERABLE_SUCCESS:
      //;
      if (
        state.pmo_deliverables.filter(
          (item) => item?._id == action.payload.data._id
        ).length > 0
      ) {
        return {
          ...state,
          loading: false,
          success: true,
          tabNum: "1",
          pmo_deliverables: state.pmo_deliverables.filter((elem) =>
            elem._id !== action.payload.data._id ? elem : null
          ),
          assignToMembers: action.payload.value,
        };
      } else {
        return {
          ...state,
          loading: false,
          success: true,
          tabNum: "1",
          pmo_deliverables: [...state.pmo_deliverables, action.payload.data],
          assignToMembers: action.payload.value,
          // assignToMembers: array,
        };
        break;
      }
    case types.CHECK_PMO_DELIVERABLE_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //check
    case types.CHECK_PMO_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.CHECK_PMO_PROJECT_SUCCESS:
      if (
        state.pmo_project.filter((item) => item?._id == action.payload.data._id)
          .length > 0
      ) {
        return {
          ...state,
          loading: false,
          success: true,
          tabNum: "2",
          pmo_project: state.pmo_project.filter((elem) =>
            elem._id !== action.payload.data._id ? elem : null
          ),
          assignToMembers: action.payload.value,
        };
      } else {
        return {
          ...state,
          loading: false,
          success: true,
          tabNum: "2",
          pmo_project: [...state.pmo_project, action.payload.data],
          assignToMembers: action.payload.value,
          // assignToMembers: array,
        };
        break;
      }
    case types.CHECK_PMO_PROJECT_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //check
    case types.CHECK_PMO_PROGRAM_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.CHECK_PMO_PROGRAM_SUCCESS:
      if (
        state.pmo_program.filter((item) => item?._id == action.payload.data._id)
          .length > 0
      ) {
        return {
          ...state,
          loading: false,
          success: true,
          tabNum: "3",
          pmo_program: state.pmo_program.filter((elem) =>
            elem._id !== action.payload.data._id ? elem : null
          ),
          assignToMembers: action.payload.value,
        };
      } else {
        return {
          ...state,
          loading: false,
          success: true,
          tabNum: "3",
          pmo_program: [...state.pmo_program, action.payload.data],
          assignToMembers: action.payload.value,
          // assignToMembers: array,
        };
      }

    case types.CHECK_PMO_PROGRAM_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //check
    case types.CHECK_PMO_PORTFOLIO_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.CHECK_PMO_PORTFOLIO_SUCCESS:
      if (
        state.pmo_portfolio.filter(
          (item) => item?._id == action.payload.data._id
        ).length > 0
      ) {
        return {
          ...state,
          loading: false,
          success: true,
          tabNum: "4",
          pmo_portfolio: state.pmo_portfolio.filter((elem) =>
            elem._id !== action.payload.data._id ? elem : null
          ),
          assignToMembers: action.payload.value,
        };
      } else {
        return {
          ...state,
          loading: false,
          success: true,
          tabNum: "4",
          pmo_portfolio: [...state.pmo_portfolio, action.payload.data],
          assignToMembers: action.payload.value,
          // assignToMembers: array,
        };
      }

    case types.CHECK_PMO_PORTFOLIO_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //check
    case types.CHECK_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.CHECK_PROJECT_SUCCESS:
      //;
      if (
        state.project.filter((item) => item?._id == action.payload.data._id)
          .length > 0
      ) {
        return {
          ...state,
          loading: false,
          success: true,
          tabNum: "5",
          project: state.project.filter((elem) =>
            elem._id !== action.payload.data._id ? elem : null
          ),
          assignToMembers: action.payload.value,
        };
      } else {
        return {
          ...state,
          loading: false,
          success: true,
          tabNum: "5",
          project: [...state.project, action.payload.data],
          assignToMembers: action.payload.value,
          // assignToMembers: array,
        };
        break;
      }
    case types.CHECK_PROJECT_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //check
    case types.CHECK_PROGRAM_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.CHECK_PROGRAM_SUCCESS:
      if (
        state.program.filter((item) => item?._id == action.payload.data._id)
          .length > 0
      ) {
        return {
          ...state,
          loading: false,
          success: true,
          tabNum: "5",
          program: state.program.filter((elem) =>
            elem._id !== action.payload.data._id ? elem : null
          ),
          assignToMembers: action.payload.value,
        };
      } else {
        return {
          ...state,
          loading: false,
          tabNum: "5",
          success: true,
          program: [...state.program, action.payload.data],
          assignToMembers: action.payload.value,
        };
      }
    case types.CHECK_PROGRAM_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //check
    case types.CHECK_PORTFOLIO_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.CHECK_PORTFOLIO_SUCCESS:
      if (
        state.portfolio.filter((item) => item?._id == action.payload.data._id)
          .length > 0
      ) {
        return {
          ...state,
          loading: false,
          success: true,
          tabNum: "5",
          portfolio: state.portfolio.filter((elem) =>
            elem._id !== action.payload.data._id ? elem : null
          ),
          assignToMembers: action.payload.value,
        };
      } else {
        return {
          ...state,
          loading: false,
          tabNum: "5",
          success: true,
          portfolio: [...state.portfolio, action.payload.data],
          assignToMembers: action.payload.value,
        };
        break;
      }
    case types.CHECK_PORTFOLIO_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //check
    case types.MODIFY_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.MODIFY_TEMPLATE_SUCCESS:
      //;
      return {
        ...state,
        loading: false,
        success: true,
        pmo_deliverables: state.pmo_deliverables.map((elem) =>
          elem._id == action.payload._id ? action.payload : elem
        ),
        pmo_project: state.pmo_project.map((elem) =>
          elem._id == action.payload._id ? action.payload : elem
        ),
        pmo_program: state.pmo_program.map((elem) =>
          elem._id == action.payload._id ? action.payload : elem
        ),
        pmo_portfolio: state.pmo_portfolio.map((elem) =>
          elem._id == action.payload._id ? action.payload : elem
        ),
      };

    case types.MODIFY_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    //check
    case types.MODIFY_WORKSTREAM_REQUEST:
      debugger;

      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.MODIFY_WORKSTREAM_SUCCESS:
      debugger;
      //;
      return {
        ...state,
        loading: false,
        success: true,

        project: state.project.map((elem) =>
          elem._id == action.payload._id ? action.payload : elem
        ),
        program: state.program.map((elem) =>
          elem._id == action.payload._id ? action.payload : elem
        ),
        portfolio: state.portfolio.map((elem) =>
          elem._id == action.payload._id ? action.payload : elem
        ),
      };

    case types.MODIFY_WORKSTREAM_FAILURE:
      debugger;

      return {
        ...state,
        loading: true,
        success: false,
      };

    //clear-all - request
    case types.CLEAR_ALL_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case types.CLEAR_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        clients: [],
        teams: [],
        teamMembers: [],
        departments: [],
        project: [],
        program: [],
        portfolio: [],
        tabNum: "1",
        assignToMembers: [],
      };
    case types.CLEAR_ALL_FAILURE:
      return {
        ...state,
        loading: true,
        success: false,
      };

    default:
      return state;
  }
};

export default filterDataReducer;
